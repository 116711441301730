<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important"> 
        <h3>
        درآمد حاصل از خدمات 
        </h3>
        </v-card-title>
      <v-card class="cards pa-3">

        <v-card-text>
          <div>
            <v-card>
              <v-card class="pa-2">
                <v-row class="time-row">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="dateFrom3">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="dateFrom"
                        
                        label=" تاریخ از "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>                      
                    </span>

                    <date-picker
                      v-model="dateFrom"
                      element="dateFrom3"
                      color="#00a7b7"
                    />
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <span id="dateTo3">
                        <v-text-field
                          outlined
                          dense
                          type="text"
                          append-icon="calendar_today"
                          v-model="dateTo"
                        
                          label=" تاریخ تا "
                          :editable="true"
                          class="date-input"
                        >
                        </v-text-field>                        
                      </span>

                    <date-picker
                      v-model="dateTo"
                      element="dateTo3"
                      color="#00a7b7"
                    />
                  </v-col>
                </v-row>
                <v-row class="time-row">

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="timeFrom">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="schedule"
                        v-model="timeFrom"
                      
                        label=" ساعت از "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>                      
                    </span>

                    <date-picker
                      v-model="timeFrom"
                      element="timeFrom"
                      color="#00a7b7"
                      type="time"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="timeTo">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="schedule"
                        v-model="timeTo"
                      
                        label=" ساعت تا "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>                      
                    </span>

                    <date-picker
                      v-model="timeTo"
                      element="timeTo"
                      color="#00a7b7"
                      type="time"
                    />
                  </v-col>
                  
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      class="submit-btn primary-btn"
                      style="float: right"
                      @click="docPercent()"
                      :disabled="busyDocPercent"
                      >اعمال</v-btn
                    >
                  </v-col>
                </v-row>
                <hr />
                <div v-if="isMounted">
                  <template>
                    <v-expansion-panels v-model="opens" multiple>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          >خدمات پزشک</v-expansion-panel-header
                        >
                        <v-expansion-panel-content class="my-3">
                          <v-row style="margin: 10px">
                            <v-col style="float: left">
                              <v-btn
                                class="secondary-btn"
                                @click="print('docPDF')"
                              >
                                پرینت
                              </v-btn>
                            </v-col>
                            <v-col>
                              <h4 class="text-center">
                                <strong
                                  >{{
                                    monthDict[moment(dateFrom).format("MM") - 1]
                                  }}
                                  ماه
                                  {{ moment(dateFrom).format("YYYY") }}</strong
                                >
                              </h4>
                            </v-col>
                            <v-col style="float: right">
                              <v-btn
                                style="float: left"
                                class="secondary-btn"
                                @click="$refs.docServicesExcel.$el.click()"
                                >دریافت اکسل</v-btn
                              >
                              <vue-excel-xlsx
                                :data="Items"
                                ref="docServicesExcel"
                                v-show="false"
                                :columns="
                                  excelFields.map(x => {
                                    return {
                                      label: x.label,
                                      field: x.key
                                    };
                                  })
                                "
                                :filename="
                                  'خدمات دکتر ' +
                                    userData.name +
                                    ' از ' +
                                    dateFrom +
                                    ' تا ' +
                                    dateTo
                                "
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx>
                            </v-col>
                          </v-row>
                          <div id="docPDF" v-show="false">
                            <!-- pdf  -->
                            <v-row class="ma-3">
                              <v-col>
                                <h4>نام پزشک: دکتر {{ userData.name }}</h4>
                              </v-col>
                              <v-col>
                                <v-row>
                                  <v-col>
                                    <h5 style="text-align: center">
                                      از: {{ dateFrom }}
                                    </h5>
                                  </v-col>
                                  <v-col>
                                    <h5 style="text-align: center">
                                      تا: {{ dateTo }}
                                    </h5>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col>
                                <h4 style="float: left">
                                  <strong
                                    >{{
                                      monthDict[
                                        moment(dateFrom).format("MM") - 1
                                      ]
                                    }}
                                    ماه
                                    {{
                                      moment(dateFrom).format("YYYY")
                                    }}</strong
                                  >
                                </h4>
                              </v-col>
                            </v-row>
                            <b-table
                              responsive
                              show-empty
                              :fields="Fields"
                              :items="Items"
                              empty-text="رکوردی برای نمایش وجود ندارد"
                              empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                              :busy="Busy"
                              :filter="Filter"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.value }}
                                </div>
                              </template>
                              <template v-slot:cell(index)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.index + 1 }}
                                </div>
                              </template>
                              <template v-slot:cell(share)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <div
                                slot="table-busy"
                                class="text-center primary--text my-2"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="align-middle"
                                ></v-progress-circular>
                              </div>
                            </b-table>
                            <hr />

                            <h5 class="text-success mb-3">
                              <strong
                                >مجموع سهم پزشک:
                                {{
                                  Items.map(x => x.share)
                                    .reduce(
                                      (a, b) => parseInt(a) + parseInt(b),
                                      0
                                    )
                                    .toLocaleString()
                                }}</strong
                              >
                              ریال
                            </h5>
                          </div>
                          <!-- pdf -->
                          <b-table
                            responsive
                            show-empty
                            :fields="Fields"
                            :items="Items"
                            empty-text="رکوردی برای نمایش وجود ندارد"
                            empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                            :busy="Busy"
                            :filter="Filter"
                            :current-page="CurrentPage"
                            :per-page="PerPage"
                            @filtered="onFiltered"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index + PerPage * (CurrentPage - 1) + 1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(share)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="CurrentPage"
                            :length="Math.ceil(TotalRows / PerPage)"
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                          ></v-pagination>
                          <v-select
                            label="تعداد در هر صفحه:"
                            style="width: 150px"
                            v-model="PerPage"
                            :items="perPageOptions"
                            item-text="text"
                            item-value="value"
                          ></v-select>
                          <hr />

                          <h5 class="text-success mb-3">
                            <strong
                              >مجموع سهم پزشک:
                              {{
                                Items.map(x => x.share)
                                  .reduce(
                                    (a, b) => parseInt(a) + parseInt(b),
                                    0
                                  )
                                  .toLocaleString()
                              }}</strong
                            >
                            ریال
                          </h5>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          >دریافتی پذیرش</v-expansion-panel-header
                        >
                        <v-expansion-panel-content class="my-3">
                          <v-row style="margin: 10px">
                            <v-col style="float: left">
                              <v-btn
                                class="secondary-btn"
                                @click="print('receptionIncome')"
                              >
                                پرینت
                              </v-btn>
                            </v-col>
                            <v-col>
                              <h4 class="text-center">
                                <strong
                                  >{{
                                    monthDict[moment(dateFrom).format("MM") - 1]
                                  }}
                                  ماه
                                  {{ moment(dateFrom).format("YYYY") }}</strong
                                >
                              </h4>
                            </v-col>
                            <v-col style="float: right">
                              <v-btn
                                style="float: left"
                                class="secondary-btn"
                                @click="$refs.recServicesExcel.$el.click()"
                                >دریافت اکسل</v-btn
                              >
                              <vue-excel-xlsx
                                :data="recItems"
                                ref="recServicesExcel"
                                v-show="false"
                                :columns="
                                  recExcelFields.map(x => {
                                    return {
                                      label: x.label,
                                      field: x.key
                                    };
                                  })
                                "
                                :filename="
                                  'خدمات دکتر ' +
                                    userData.name +
                                    ' از ' +
                                    dateFrom +
                                    ' تا ' +
                                    dateTo
                                "
                                :sheetname="currentDate"
                              >
                                دریافت اکسل
                              </vue-excel-xlsx>
                            </v-col>
                          </v-row>
                          <div id="receptionIncome" v-show="false">
                            <!-- pdf  -->
                            <v-row class="ms-3 me-3 mt-2">
                              <v-col>
                                <h3>نام پزشک: دکتر {{ userData.name }}</h3>
                              </v-col>
                              <v-col>
                                <v-row>
                                  <v-col>
                                    <h5 style="text-align: center">
                                      از: {{ dateFrom }}
                                    </h5>
                                  </v-col>
                                  <v-col>
                                    <h5 style="text-align: center">
                                      تا: {{ dateTo }}
                                    </h5>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col>
                                <h4 style="float: left">
                                  <strong
                                    >{{
                                      monthDict[
                                        moment(dateFrom).format("MM") - 1
                                      ]
                                    }}
                                    ماه
                                    {{
                                      moment(dateFrom).format("YYYY")
                                    }}</strong
                                  >
                                </h4>
                              </v-col>
                            </v-row>
                            <b-table
                              responsive
                              show-empty
                              :fields="recFields"
                              :items="recItems"
                              empty-text="رکوردی برای نمایش وجود ندارد"
                              empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                              :busy="Busy"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.value }}
                                </div>
                              </template>
                              <template v-slot:cell(index)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.index + 1 }}
                                </div>
                              </template>
                              <template v-slot:cell(cost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <div
                                slot="table-busy"
                                class="text-center primary--text my-2"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="align-middle"
                                ></v-progress-circular>
                              </div>
                            </b-table>
                            <hr />

                            <h5 class="text-success mb-3">
                              <strong
                                >مجموع دریافتی پذیرش:
                                {{
                                  recItems
                                    .map(x => x.cost)
                                    .reduce(
                                      (a, b) => parseInt(a) + parseInt(b),
                                      0
                                    )
                                    .toLocaleString()
                                }}</strong
                              >
                              ریال
                            </h5>
                          </div>
                          <!-- pdf -->
                          <b-table
                            responsive
                            show-empty
                            :fields="recFields"
                            :items="recItems"
                            empty-text="رکوردی برای نمایش وجود ندارد"
                            empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                            :busy="Busy"
                            :current-page="recCurrentPage"
                            :per-page="recPerPage"
                            @filtered="reconFiltered"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                    recPerPage * (recCurrentPage - 1) +
                                    1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="recCurrentPage"
                            :length="Math.ceil(recTotalRows / recPerPage)"
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                          ></v-pagination>
                          <v-select
                            label="تعداد در هر صفحه:"
                            style="width: 150px"
                            v-model="recPerPage"
                            :items="perPageOptions"
                            item-text="text"
                            item-value="value"
                          ></v-select>
                          <hr />

                          <h5 class="text-success mb-3">
                            <strong
                              >مجموع دریافتی پذیرش:
                              {{
                                recItems
                                  .map(x => x.cost)
                                  .reduce(
                                    (a, b) => parseInt(a) + parseInt(b),
                                    0
                                  )
                                  .toLocaleString()
                              }}</strong
                            >
                            ریال
                          </h5>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          >درآمد پزشک</v-expansion-panel-header
                        >
                        <v-expansion-panel-content class="my-3">
                          <v-row style="margin: 10px">
                            <v-col>
                              <v-btn
                                class="secondary-btn"
                                @click="print('docIncomePDF')"
                              >
                                پرینت
                              </v-btn>
                            </v-col>
                            <v-col>
                              <h4 style="float: left">
                                <strong
                                  >{{
                                    monthDict[moment(dateFrom).format("MM") - 1]
                                  }}
                                  ماه
                                  {{ moment(dateFrom).format("YYYY") }}</strong
                                >
                              </h4>
                            </v-col>
                          </v-row>
                          <div
                            id="docIncomePDF"
                            style="font-family: B Nazanin"
                            v-show="false"
                          >
                            <!-- NOTE pdf  -->
                            <v-row class="ma-3">
                              <v-col>
                                <h3>نام پزشک: دکتر {{ userData.name }}</h3>
                              </v-col>
                              <v-col>
                                <v-row>
                                  <v-col>
                                    <h5 style="text-align: center">
                                      از: {{ dateFrom }}
                                    </h5>
                                  </v-col>
                                  <v-col>
                                    <h5 style="text-align: center">
                                      تا: {{ dateTo }}
                                    </h5>
                                  </v-col>
                                  <v-col>
                                    <h4 style="float: left">
                                      <strong
                                        >{{
                                          monthDict[
                                            moment(dateFrom).format("MM") - 1
                                          ]
                                        }}
                                        ماه
                                        {{
                                          moment(dateFrom).format("YYYY")
                                        }}</strong
                                      >
                                    </h4>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <b-table
                              responsive
                              show-empty
                              :fields="incomeFields"
                              :items="income.shift"
                              empty-text="رکوردی برای نمایش وجود ندارد"
                              empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                              :busy="Busy"
                              :filter="Filter"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{
                                    typeof data.value === "number"
                                      ? Number(data.value).toLocaleString()
                                      : data.value
                                  }}
                                </div>
                              </template>
                              <template v-slot:cell(index)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.index + 1 }}
                                </div>
                              </template>
                              <div
                                slot="table-busy"
                                class="text-center primary--text my-2"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="align-middle"
                                ></v-progress-circular>
                              </div>
                            </b-table>
                            <br />
                            <v-row>
                              <v-col>
                                <h5><strong>جمع کل: </strong></h5>
                              </v-col>
                              <v-col>
                                <h6>
                                  <strong
                                    >ویزیت:
                                    {{
                                      Number(
                                        income.allTotal.totalVisitIncome
                                      ).toLocaleString()
                                    }}</strong
                                  >
                                </h6>
                              </v-col>
                              <v-col>
                                <h6>
                                  <strong
                                    >خدمات:
                                    {{
                                      Number(
                                        income.allTotal.totalBaliniIncome
                                      ).toLocaleString()
                                    }}</strong
                                  >
                                </h6>
                              </v-col>
                              <v-col>
                                <h6>
                                  <strong
                                    >کارکرد:
                                    {{
                                      Number(
                                        income.allTotal.totalTotalIncome
                                      ).toLocaleString()
                                    }}</strong
                                  >
                                </h6>
                              </v-col>
                            </v-row>
                            <hr />

                            <b-table
                              responsive
                              show-empty
                              :fields="paymentFields"
                              :items="income.payments"
                              empty-text="رکوردی برای نمایش وجود ندارد"
                              empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                              :busy="Busy"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.value }}
                                </div>
                              </template>
                              <template v-slot:cell(index)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{
                                    data.index + PerPage * (CurrentPage - 1) + 1
                                  }}
                                </div>
                              </template>
                              <template v-slot:cell(payment)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <div
                                slot="table-busy"
                                class="text-center primary--text my-2"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="align-middle"
                                ></v-progress-circular>
                              </div>
                            </b-table>
                          </div>
                          <!-- pdf -->
                          <b-table
                            responsive
                            show-empty
                            :fields="incomeFields"
                            :items="income.shift"
                            empty-text="رکوردی برای نمایش وجود ندارد"
                            empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                            :busy="Busy"
                            :filter="Filter"
                            :current-page="incomeCurrentPage"
                            :per-page="incomePerPage"
                            @filtered="incomeonFiltered"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  typeof data.value === "number"
                                    ? Number(data.value).toLocaleString()
                                    : data.value
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                    incomePerPage * (incomeCurrentPage - 1) +
                                    1
                                }}
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-row v-if="userData.expertise == 'عمومی'">
                            <v-col>
                              <h5><strong>جمع کل: </strong></h5>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >ویزیت:
                                  {{
                                    Number(
                                      income.allTotal.totalVisitIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >خدمات پرستاری:
                                  {{
                                    Number(
                                      income.allTotal.totalBaliniIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >خدمات پزشک:
                                  {{
                                    Number(
                                      income.allTotal.totalSurgeryIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >کارکرد:
                                  {{
                                    Number(
                                      income.allTotal.totalTotalIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                          </v-row>
                          <v-row v-if="userData.expertise == 'دندانپزشک'">
                            <v-col>
                              <h5><strong>جمع کل: </strong></h5>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >کارکرد دندانپزشکی:
                                  {{
                                    Number(
                                      income.allTotal.totalDentistryIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >کارکرد:
                                  {{
                                    Number(
                                      income.allTotal.totalTotalIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="
                              userData.expertise != 'عمومی' &&
                                userData.expertise != 'دندانپزشک'
                            "
                          >
                            <v-col>
                              <h5><strong>جمع کل: </strong></h5>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >ویزیت:
                                  {{
                                    Number(
                                      income.allTotal.totalExpertVisitIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >خدمات:
                                  {{
                                    Number(
                                      income.allTotal.totalExpertClinicalIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                            <v-col>
                              <h6>
                                <strong
                                  >کارکرد:
                                  {{
                                    Number(
                                      income.allTotal.totalTotalIncome
                                    ).toLocaleString()
                                  }}</strong
                                >
                              </h6>
                            </v-col>
                          </v-row>
                          <v-pagination
                            v-model="incomeCurrentPage"
                            :length="Math.ceil(incomeTotalRows / incomePerPage)"
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                          ></v-pagination>
                          <v-select
                            label="تعداد در هر صفحه:"
                            style="width: 150px"
                            v-model="incomePerPage"
                            :items="perPageOptions"
                            item-text="text"
                            item-value="value"
                          ></v-select>

                          <hr />
                          <b-table
                            responsive
                            show-empty
                            :fields="paymentFields"
                            :items="income.payments"
                            empty-text="رکوردی برای نمایش وجود ندارد"
                            empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                            :busy="Busy"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index + PerPage * (CurrentPage - 1) + 1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(payment)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </div>
              </v-card>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  components: {
    datePicker: VuePersianDatetimePicker
  },
  data() {
    return {
      tab: null,
      opens: [0, 1, 2],
      isMounted: false,
      monthDict: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند"
      ],
      moment: moment,
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      userData: {},
      CurrentPage: 1,
      recCurrentPage: 1,
      PerPage: 10,
      recPerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" }
      ],
      TotalRows: "",
      recTotalRows: "",
      incomeCurrentPage: 1,
      incomePerPage: 10,
      incomeTotalRows: "",
      Filter: "",
      Busy: true,
      busyDocPercent: false,
      busyDocIncome: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
        { key: "share", label: "سهم پزشک" }
      ],
      recFields: [
        { key: "index", label: "#" },
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
        { key: "cost", label: "دریافتی پذیرش" }
      ],
      excelFields: [
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
        { key: "share", label: "سهم پزشک" }
      ],
      recExcelFields: [
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
        { key: "cost", label: "دریافتی پذیرش" }
      ],
      Items: [],
      recItems: [],
      paymentFields: [
        { key: "item", label: "آیتم پرداختی" },
        { key: "payment", label: "مبلغ پرداختی" }
      ],
      income: {},
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD")
    };
  },
  computed: {
    incomeFields() {
      if (this.userData.expertise == "عمومی") {
        return [
          { key: "index", label: "#" },
          { key: "date", label: "تاریخ" },
          { key: "visitIncome", label: "مبلغ کارکرد ویزیت" },
          { key: "baliniIncome", label: "مبلغ کارکرد خدمات پرستاری" },
          { key: "surgeryIncome", label: "مبلغ کارکرد خدمات پزشک" },
          { key: "totalIncome", label: "جمع کارکرد" }
        ];
      } else if (this.userData.expertise == "دندانپزشک") {
        return [
          { key: "index", label: "#" },
          { key: "date", label: "تاریخ" },
          { key: "dentistryIncome", label: "مبلغ کارکرد دندانپزشک" },
          { key: "totalIncome", label: "جمع کارکرد" }
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "date", label: "تاریخ" },
          { key: "expertVisitIncome", label: "مبلغ کارکرد ویزیت" },
          { key: "expertClinicalIncome", label: "مبلغ کارکرد خدمات" },
          { key: "totalIncome", label: "جمع کارکرد" }
        ];
      }
    }
  },
  methods: {
    docPercent() {
      this.Busy = true;
      this.busyDocPercent = true;
      this.busyDocIncome = true;
      this.docRequests();
      this.docIncome();
      this.recRequests();
    },
    docRequests() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/doctorIdPercentCopy",
          {
            doctorId: this.$route.params.id,
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          if (res.status == 200) {
            this.busyDocPercent = false;
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.Busy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
            this.busyDocPercent = false;
          }
        })
        .catch(err => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
          this.busyDocPercent = false;
        });
    },
    recRequests() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/doctorIdCost",
          {
            doctorId: this.$route.params.id,
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          if (res.status == 200) {
            this.busyDocPercent = false;
            this.recItems = res.data;
            this.recTotalRows = this.recItems.length;
            this.Busy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
            this.busyDocPercent = false;
          }
        })
        .catch(err => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
          this.busyDocPercent = false;
        });
    },
    docIncome() {
      this.isMounted = false;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/doctorIdIncomeCopy",
          {
            doctorId: this.$route.params.id,
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          if (res.status == 200) {
            this.busyDocIncome = false;
            this.income = res.data;
            this.incomeTotalRows = this.income.shift.length;
            this.Busy = false;
            this.isMounted = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
            this.busyDocIncome = false;
          }
        })
        .catch(err => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
          this.busyDocPercent = false;
        });
    },
    showDocInfo() {
      //NOTE getting doc infos when page mount
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/doctorId",
          {
            doctorId: this.$route.params.id
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          if (res.status == 200) {
            this.userData = res.data;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch(err => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    print(div) {
      //NOTE making pdf of div elements
      this.$htmlToPaper(div);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
    reconFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.recTotalRows = filteredItems.length;
      this.recCurrentPage = 1;
    },
    incomeonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.incomeTotalRows = filteredItems.length;
      this.incomeCurrentPage = 1;
    }
  },

  mounted() {
    this.showDocInfo();
    this.docPercent();
  }
};
</script>
